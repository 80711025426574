.m {
  display: flex;
  justify-content: space-around;
  background-color: #282c34;
}

.next {
  background: green;
  font-size: larger;
  border-radius: 15px;
  border: none;
}

.previous {
  background: blueviolet;
  font-size: larger;
  border-radius: 15px;
  border: none;
}
